import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react';

export default function AboutPage() {

  useEffect(() => {
    Aos.init({duration: 500});
    setTimeout(() => {
      Aos.refresh();
    }, 100);
  }, []);


  return (
      <div className="about-wrapper">
        <div className="text-wrapper">
          <div className="second">
            <h1 data-aos="fade-left" className="about-title">
              Gladi i Gradi d.o.o
            </h1>
            <p data-aos="fade-right"  className="text">
            Gladi i gradi d.o.o. is a company formed through the merger of Goran Suton, the owner of Suton na bregu d.o.o., and Dino Janković, the owner of Altius d.o.o.
            </p>
          </div>


          <h1 data-aos="fade-left" className="about-title">
            Goran Suton
          </h1>
          <p data-aos="fade-right" className="text">
          Goran Suton is a graduate civil engineer with extensive experience in residential construction. Throughout his thirty-year career, he has been involved in the construction of residential buildings in Zagreb, as well as luxury houses along the Adriatic Sea coast. After becoming independent, his construction portfolio can be seen on the website sutonnabregu.hr.
          </p>

          <div className="second">
            <h1 data-aos="fade-left" className="about-title">
              Dino Janković
            </h1>
            <p data-aos="fade-right" className="text">
            After nine years of experience as the Treasury Director at Erste Bank, Dino Janković has been working for the past 20 years or so as the Financial Director and Financial Advisor at the pharmaceutical company Grunenthal GmbH (later acquired by Stada GmbH). Additionally, he invests funds and conducts financial transactions through his own company, Altius d.o.o.
            </p>
          </div>


          <h1 data-aos="fade-left" className="about-title">
            Partnership
          </h1>
          <p data-aos="fade-right" className="text">
          Dino and Goran are long-time friends who were initially brought together by their love for basketball, and later by mutual respect, fairness, reliability, and the reputation they both gained throughout their professional careers.

Knowledge, perseverance, innovation, and meticulousness are just some of the virtues that have marked their successful, long-standing careers. Their business synergy through the company Gladi i gradi d.o.o. is a recipe for excellent products and a guarantee of quality and customer satisfaction for every potential buyer of their real estate.





          </p>
          </div>
    </div>
  )
}
