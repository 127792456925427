import React, { useEffect } from 'react'
import '../styles/style.css'
import MyImage from '../images/pogled I2.webp'
import MyImage2 from '../images/pogled J.jpg'
import MyImage3 from '../images/pogled J-Z.jpg'
import MyImage4 from '../images/pogled S.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Slika from '../images/homescreenfour.webp'
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';

export default function HomePage() {

  useEffect(() => {
    Aos.init({duration: 500});
    setTimeout(() => {
      Aos.refresh();
    }, 100);
  }, []);



  return (
    <>
    <div className="page-container">
        <Carousel className="main-carousel" indicators={false}>
          <Carousel.Item>
            <img className="main-img" src={MyImage} alt="image that shows houses" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="main-img" src={MyImage2} alt="image that shows houses" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="main-img" src={MyImage3} alt="image that shows houses" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="main-img" src={MyImage4} alt="image that shows houses" />
          </Carousel.Item>
        </Carousel>
      <h1 className="title">Gladi i Gradi d.o.o</h1>
      <div className="button-container">
        <button data-aos="fade-up"><Link className="button-link" to="/Properties">See projects</Link></button>
      </div>
      <div className="home-paragraph">
  <p data-aos="fade-up">
    Located near the local sand beach, houses are conceived in the way that their own orientation and organization, 
    and horticultural arrangement of gardens, ensures to each apartment <strong>maximum security</strong>.
  </p>
  
  <p data-aos="fade-up">
    Each residential unit extends across three floors connected by an internal staircase. On the ground floor, 
    there is a living room with a kitchen and dining area that expands onto a spacious covered terrace 
    through a large glass wall. The terrace is followed by a <strong>pool</strong> with a <strong>sun deck</strong> 
    and a <strong>garden</strong>.
  </p>
  
  <p data-aos="fade-up">
    On the first floor, there are three bedrooms, two bathrooms, and a loggia. On the second floor, there is another 
    bedroom with a bathroom, a multifunctional space with a <strong>tea kitchen</strong>, and a large terrace 
    with a <strong>Jacuzzi</strong>.
  </p>
  
  <p data-aos="fade-up">
    Each apartment is provided with <strong>two parking spaces</strong> on the premises. 
    Pleasant microclimate in winter and summer is ensured by an <strong>energy-efficient</strong> 
    underfloor heating system with its own heat pumps and multisplit cooling system with indoor climate units in each room.
  </p>
  
  <p data-aos="fade-up">
    The houses are well-insulated (<em>energy class A</em>), and each residential unit has its own 
    <strong>photovoltaic power plant</strong> on the roof, ensuring <strong>high energy efficiency</strong> 
    and minimal operating costs.
  </p>
  
  <p data-aos="fade-up">
    <em>High construction standards</em> and quality finishing materials complete the creation of a space 
    that will provide <strong>comfort</strong> and <strong>satisfaction</strong> to its future residents.
  </p>
</div>


    <footer className="footer-main">
      <p className="text-footer">goran@gladigradi.hr | +385 91 453 2944<br></br> dino@gladigradi.hr | +385 91 433 3211</p>
      <p className="text-footer">@Copyright Gladi i Gradi d.o.o 2024</p>
    </footer>
    </div>
    </>
  )
}
