import React, { useEffect } from 'react';
import '../styles/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

export default function ContactPage() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
    setTimeout(() => {
      Aos.refresh();
    }, 100);
  }, []);

  const sendEmail = () => {
    const fullName = document.getElementById('full-name').value;
    const message = document.getElementById('message').value;
    const emailAddress = 'goran@gladigradi.hr';
    const emailAdress2 = 'dino@gladigradi.hr';
    const subject = 'Contact Inquiry';

    const mailtoLink = `mailto:${emailAddress},${emailAdress2}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(`Puno Ime: ${fullName}\n\nPoruka: ${message}`)}`;

    window.open(mailtoLink);
  };

  return (
    <div className="contact-container">
      <div className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </div>

      <div className="contact-wrapper" id="contact-wrap">
        <div data-aos="fade-down" className="contact-left" id="cont-left">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d45717.24045802355!2d15.151273800028598!3d44.26208094207147!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47621e48291456d3%3A0xaadb64bf7fa05b76!2sMletak%2C%2023233%2C%20Privlaka!5e0!3m2!1shr!2shr!4v1661112864923!5m2!1shr!2shr"
            width="600"
            height="450"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div data-aos="fade-up" className="contact-right" id="cont-right">
          <h1 data-aos="fade-up" className="reveal fade-bottom" id="contact-title">
            Email
          </h1>
          <input data-aos="fade-left" className="reveal fade-left" placeholder="Full Name" id="full-name" />
          <input data-aos="fade-left" className="reveal fade-left" placeholder="Message" id="message" />
          <button
            data-aos="zoom-in"
            name="submit contact form"
            className="submit-contact reveal grow"
            onClick={sendEmail}
          >
            Send
          </button>
          <h2 className="reveal opacity-anim">Based in: Privlaka, Croatia</h2>
          <h2 className="reveal fade-bottom">@Copyright Gladi i Gradi d.o.o 2024</h2>
        </div>
      </div>
    </div>
  );
}